import React from 'react';
import {
  LockClosedIcon, MailIcon, UserIcon, PhoneIcon,
} from '@heroicons/react/outline';
import { useLink } from 'src/hooks';
import Link from 'src/components/core/Link';
import Input from 'src/components/core/Input';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';
import { pxToRem, spacing } from 'src/theme';
import PhoneNumberField from 'src/components/PhoneNumberField';
import { useRegistration } from './utils';
import SignInPrompt from '../SignInPrompt';

interface Props {
  hasPlan: boolean,
}

const RegisterForm: React.FC<Props> = ({ hasPlan }) => {
  const { href: loginHref } = useLink('/login/');
  const { href: forgotPasswordHref } = useLink('/forgot_password/');
  const {
    data,
    inputErrors: errors,
    loading,
    showInputErrors,
    handleChange,
    handleRegister,
    showConflictError,
  } = useRegistration();

  return (
    <>
      <Typography
        variant={hasPlan ? 'h2' : 'h3'}
        color="blue"
        style={{ fontWeight: 700 }}
      >
        Get Started
      </Typography>
      <Typography
        style={{
          marginBottom: spacing(7),
        }}
        variant={hasPlan ? 'p2' : 'p3'}
        color="textSecondary"
      >
        Access your all-in-one business platform with us.
      </Typography>
      <form onSubmit={handleRegister}>
        <div className={hasPlan ? 'grid grid-cols-1 lg:grid-cols-2 gap-x-6' : 'grid grid-cols-1 sm:grid-cols-2 gap-x-6'}>
          <Input
            label="First Name"
            labelIcon={UserIcon}
            name="first-name"
            value={data.firstName}
            error={showInputErrors && errors.firstName}
            onChange={(v) => handleChange('firstName', v)}
            inputProps={{
              autoComplete: 'given-name',
              placeholder: 'First Name',
            }}
            className="mb-4"
          />
          <Input
            label="Last Name"
            labelIcon={UserIcon}
            name="last-name"
            value={data.lastName}
            error={showInputErrors && errors.lastName}
            onChange={(v) => handleChange('lastName', v)}
            inputProps={{
              autoComplete: 'family-name',
              placeholder: 'Last Name',
            }}
            className="mb-4"
          />
        </div>
        <Input
          label="Email Address"
          labelIcon={MailIcon}
          name="email"
          value={data.email}
          error={showInputErrors && errors.email}
          onChange={(v) => handleChange('email', v)}
          inputProps={{
            autoComplete: 'email-address',
            placeholder: 'you@example.com',
          }}
          className="mb-4"
        />
        <Input
          label="Create Password"
          labelIcon={LockClosedIcon}
          name="phone"
          error={showInputErrors && errors.password}
          value={data.password}
          onChange={(v) => handleChange('password', v)}
          sensitive
          inputProps={{
            autoComplete: 'new-password',
            placeholder: '************',
          }}
          className="mb-4"
        />
        <PhoneNumberField
          label="Phone Number"
          optional
          labelIcon={PhoneIcon}
          showError={showInputErrors}
          value={data.phoneNumber}
          name="phone-number"
          onChange={(v) => handleChange('phoneNumber', v)}
          inputProps={{
            placeholder: '(000) 000-0000 Ext. 000',
            autoComplete: 'tel',
          }}
        />
        <Typography
          variant="p3"
          color="textSecondary"
          className="mt-4"
          style={{ fontSize: pxToRem(12) }}
        >
          By creating an account, you agree to our&nbsp;
          <Link color="blue" href="/policy/" isExternal>
            Terms of Service
          </Link>
          .
        </Typography>
        {showConflictError && (
          <div className="mt-4 p-3 bg-error-background rounded w-full">
            <Typography variant="p3" color="error">
              This email is already registered to an account!
              <br />
              If this is your account, you can
              {' '}
              <Link href={loginHref} color="blue">
                Sign in
              </Link>
              {' or '}
              <Link href={forgotPasswordHref} color="blue">
                Reset Your Password
              </Link>
            </Typography>
          </div>
        )}
        <Button
          className="mt-5 mb-3 w-full"
          type="submit"
          variant="filled"
          color="teal"
          disabled={loading}
        >
          {hasPlan ? 'Create Account' : 'Register'}
        </Button>
        <SignInPrompt />
      </form>
    </>
  );
};

export default RegisterForm;
