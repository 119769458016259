import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import NoPlanForm from 'src/sections/Auth/Register/NoPlanForm';
import ApiWrapper from 'src/components/ApiWrapper';

const RegisterPage: React.FC = () => (
  <ApiWrapper apiServices={['auth-api', 'home-api', 'instance-api']} fetchLoginStatus>
    <Layout>
      <Seo title="Sign Up - Bizwise" />
      <NoPlanForm />
    </Layout>
  </ApiWrapper>
);

export default RegisterPage;
