import React from 'react';
import { pxToRem } from 'src/theme';
import SectionContainer from '../../shared/SectionContainer';
import AuthBackground from '../Background';
import Form from './Form';

const NoPlanForm: React.FC = () => (
  <SectionContainer background="light-blue" style={{ padding: 0 }}>
    <AuthBackground showPhotos>
      <div className="py-8 px-8 sm:px-16 sm:py-10" style={{ maxWidth: pxToRem(640) }}>
        <Form hasPlan={false} />
      </div>
    </AuthBackground>
  </SectionContainer>
);

export default NoPlanForm;
