import React from 'react';
import Typography from 'src/components/core/Typography';
import Clickable from 'src/components/core/Clickable';
import Link from 'src/components/core/Link';
import { useLink } from 'src/hooks';

function SignInPrompt() {
  const { href, onNavigate } = useLink('/login/');
  const handleSignIn = () => {
    onNavigate();
  };
  return (
    <Clickable onClick={handleSignIn}>
      <Typography
        variant="p3"
        color="blue"
        style={{ fontWeight: 600 }}
      >
        Already have an account?&nbsp;
        <Link color="teal" href={href}>
          Sign in
        </Link>
      </Typography>
    </Clickable>
  );
}

export default SignInPrompt;
